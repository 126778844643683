import React from 'react'
import {useNavigation} from '@react-navigation/native'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {FeedSourceInfo} from '#/state/queries/feed'
import {useSession} from '#/state/session'
import {NavigationProp} from 'lib/routes/types'
import {TopNav} from '#/view/shell/TopNav'
import {RenderTabBarFnProps} from 'view/com/pager/Pager'
import {useTheme} from '#/alf'
import {HomeTabBar} from '../pager/HomeTabBar'
import {TabBar} from '../pager/TabBar'
import {HomeHeaderLayout} from './HomeHeaderLayout'

export function HomeHeader(
  props: RenderTabBarFnProps & {
    testID?: string
    onPressSelected: () => void
    feeds: FeedSourceInfo[]
  },
) {
  const feeds = props.feeds.filter(f => f.route.params.rkey !== 'following')
  const {hasSession} = useSession()
  const navigation = useNavigation<NavigationProp>()
  const pal = usePalette('default')
  const {isMobile} = useWebMediaQueries()
  const t = useTheme()

  const hasPinnedCustom = React.useMemo<boolean>(() => {
    if (!hasSession) return false
    return feeds.some(tab => {
      const isFollowing = tab.uri === 'following'
      return !isFollowing
    })
  }, [feeds, hasSession])

  const items = React.useMemo(() => {
    const pinnedNames = feeds.map(f => f.displayName)
    // if (!hasPinnedCustom) {
    //   return pinnedNames.concat('Feeds ✨')
    // }
    return pinnedNames
  }, [
    // hasPinnedCustom,
    feeds,
  ])

  const onPressFeedsLink = React.useCallback(() => {
    navigation.navigate('Feeds')
  }, [navigation])

  const onSelect = React.useCallback(
    (index: number) => {
      if (!hasPinnedCustom && index === items.length - 1) {
        onPressFeedsLink()
      } else if (props.onSelect) {
        props.onSelect(index)
      }
    },
    [hasPinnedCustom, items.length, props, onPressFeedsLink],
  )

  return (
    <HomeHeaderLayout tabBarAnchor={props.tabBarAnchor}>
      {hasSession && <TopNav />}
      {hasSession ? (
        <HomeTabBar
          key={items.join(',')}
          onPressSelected={props.onPressSelected}
          selectedPage={props.selectedPage}
          onSelect={onSelect}
          testID={props.testID}
          items={items}
          indicatorColor={pal.colors.link}
          hasMenu={isMobile}
          isCentered={isMobile}
          textStyle={{
            color: t.palette.black_25,
            fontWeight: '400',
            paddingHorizontal: 20,
          }}
          selectedTextStyle={{
            fontSize: 18,
            fontWeight: '600',
            color: t.atoms.text.color,
            paddingHorizontal: 20,
          }}
          isHoverStyle={false}
        />
      ) : (
        <TabBar
          key={items.join(',')}
          onPressSelected={props.onPressSelected}
          selectedPage={props.selectedPage}
          onSelect={onSelect}
          testID={props.testID}
          items={items}
          indicatorColor={pal.colors.link}
          hasMenu={isMobile}
          isCentered={isMobile}
          textStyle={{
            color: t.palette.black_25,
            fontWeight: '400',
            paddingHorizontal: 20,
          }}
          selectedTextStyle={{
            fontSize: 18,
            fontWeight: '600',
            color: t.atoms.text.color,
            paddingHorizontal: 20,
          }}
          isHoverStyle={false}
        />
      )}
    </HomeHeaderLayout>
  )
}
