import React, {useCallback} from 'react'
import {Image, Pressable, StyleSheet, Text, View} from 'react-native'
import {AppBskyFeedDefs} from '@atproto/api'
import {AtUri} from '@atproto/api'
import {Trans} from '@lingui/macro'
import {useNavigation} from '@react-navigation/native'
import {ethers} from 'ethers'

import {isNative} from '#/platform/detection'
import {Shadow} from '#/state/cache/post-shadow'
import {useModalControls} from '#/state/modals'
import {usePayWalletQuery} from '#/state/queries/link-wallet'
import {
  useDonateTransSummaryQuery,
  useStakeTransSummaryQuery,
} from '#/state/queries/transaction-bet'
import {useRequireAuth, useSession} from '#/state/session'
import {NavigationProp} from 'lib/routes/types'
import * as Toast from 'view/com/util/Toast'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
// import {ArrowTriangleBottom_Stroke2_Corner1_Rounded as ArrowTriangleBottom} from '#/components/icons/ArrowTriangle'
import {DonateOnIcon, StakeOnIcon} from '#/components/icons/StakeIcons'
import {formatShortOnly} from '../util/numeric/format'
import {UserAvatar} from '../util/UserAvatar'

const usdcIcon = require('../../../../assets/imgs/usdc.png')

export interface IGuarantors {
  avatar: string
  handle: string
  displayName: string
  did: string
}

export function PostStakeDonateItem({
  post,
  outside = false,
  isAnonymousPost = false,
}: {
  post: Shadow<AppBskyFeedDefs.PostView>
  outside?: boolean
  isAnonymousPost?: boolean
}) {
  const t = useTheme()
  const requireAuth = useRequireAuth()
  const {currentAccount} = useSession()
  const {data: postPayWallet} = usePayWalletQuery(
    post.author.did ?? '',
    !outside,
    // @ts-ignore
    post?.donateCount ?? 0,
  )
  const navigation = useNavigation<NavigationProp>()
  const {openModal} = useModalControls()
  const {data: stakeSummary} = useStakeTransSummaryQuery(
    post.uri,
    !outside,
    // @ts-ignore
    post?.stakeCount ?? 0,
  )
  const {data: donateSummary} = useDonateTransSummaryQuery(
    post.uri,
    !outside,
    0,
  )
  const guarantors = (post?.guarantors as Array<IGuarantors>) || []
  const totalStakeValue = stakeSummary?.totalStakeAmount
    ? Number(
        ethers.formatUnits(
          stakeSummary?.totalStakeAmount - stakeSummary?.totalWithdrawAmount,
          6,
        ),
      )
    : 0
  const totalDonateValue = outside
    ? Number(post?.donateCount ?? 0)
    : donateSummary?.totalDonor ?? 0
  // @ts-ignore
  const isFromCircle =
    // @ts-ignore
    post?.record?.visibleCircles?.[0] === 'Public' ? false : true

  const onPressStake = useCallback(() => {
    requireAuth(() => {
      if (!outside && totalStakeValue > 0) {
        navigation.navigate('BetList', {
          did: post.author.did,
          dataType: 'stake',
          rkey: new AtUri(post.uri).rkey,
        })
      } else {
        openModal({
          name: 'stake-donate',
          type: 'stake',
          rKey: new AtUri(post.uri).rkey,
          transPostAuthor: {
            did: post.author.did,
            payWallet: postPayWallet,
            avatar: post.author.avatar ?? '',
            displayName:
              post.author.displayName && post.author.displayName !== ''
                ? post.author.displayName
                : formatHandler(post.author.handle ?? ''),
          },
        })
      }
    })
  }, [
    navigation,
    openModal,
    post?.author?.avatar,
    post?.author?.did,
    post?.author?.displayName,
    post?.author?.handle,
    post?.uri,
    postPayWallet,
    requireAuth,
    totalStakeValue,
    outside,
  ])

  const onPressDonate = useCallback(() => {
    requireAuth(() => {
      if (!outside && totalDonateValue > 0) {
        navigation.navigate('BetList', {
          did: post.author.did,
          dataType: 'donate',
          rkey: new AtUri(post.uri).rkey,
        })
      } else if (!postPayWallet || postPayWallet === '') {
        if (post.author.did === currentAccount?.did) {
          openModal({name: 'detect-link-wallet'})
        } else {
          Toast.show(
            `@${
              isAnonymousPost
                ? 'Anonymous'
                : post.author.displayName && post.author.displayName !== ''
                ? post.author.displayName
                : formatHandler(post.author.handle ?? '')
            } has been notified to link a receving wallet`,
          )
        }
      } else {
        openModal({
          name: 'stake-donate',
          type: 'donate',
          rKey: new AtUri(post.uri).rkey,
          transPostAuthor: {
            did: post.author.did,
            payWallet: postPayWallet,
            avatar: post.author.avatar ?? '',
            displayName:
              post.author.displayName && post.author.displayName !== ''
                ? post.author.displayName
                : formatHandler(post.author.handle ?? ''),
            isAnonymousPost,
          },
        })
      }
    })
  }, [
    currentAccount?.did,
    navigation,
    openModal,
    post?.author?.avatar,
    post?.author?.did,
    post?.author?.displayName,
    post?.author?.handle,
    post?.uri,
    postPayWallet,
    requireAuth,
    totalDonateValue,
    isAnonymousPost,
    outside,
  ])

  const onPressGuarantors = useCallback(() => {
    requireAuth(() => {
      navigation.navigate('BetList', {
        did: post.author.did,
        dataType: 'guarantors',
        rkey: new AtUri(post.uri).rkey,
      })
    })
  }, [navigation, requireAuth, post?.uri, post?.author?.did])

  return (
    <>
      {(!outside ||
        !!totalStakeValue ||
        !!totalDonateValue ||
        guarantors.length > 0) && (
        <View style={[styles.transContent]}>
          {/* Stake */}
          {(!outside || !!totalStakeValue) && !isNative && !isFromCircle && (
            <Pressable
              accessibilityRole="button"
              testID={`postStakeList-by-${post.uri}`}
              style={[
                styles.showStakeBtn,
                {backgroundColor: t.palette.primary_10},
              ]}
              onPress={onPressStake}>
              {totalStakeValue ? (
                <Image
                  testID="usdcLogo"
                  source={usdcIcon}
                  style={styles.transBtnIcon}
                  accessibilityIgnoresInvertColors
                />
              ) : (
                <StakeOnIcon
                  style={{
                    transform: [{scale: 0.7}, {translateX: 0}, {translateY: 0}],
                  }}
                />
              )}
              <Text style={[a.text_xs, a.font_extrabold]}>
                {totalStakeValue
                  ? `$${formatShortOnly(totalStakeValue, 2)} Endorse`
                  : 'Stake to Endorse'}
              </Text>
              {/* <ArrowTriangleBottom
                size="xxs"
                style={{
                  color: t.palette.contrast_975,
                  width: 6,
                  height: 6,
                  marginTop: 2,
                }}
              /> */}
            </Pressable>
          )}
          {/* guarantors */}
          {!!guarantors.length && !isFromCircle && (
            <Pressable
              accessibilityRole="button"
              style={[styles.showStakeBtn, {backgroundColor: '#C9D1FF'}]}
              onPress={onPressGuarantors}>
              <UserAvatar avatar={guarantors[0].avatar} size={20} />
              {guarantors[1] && (
                <View
                  style={{
                    padding: 2,
                    backgroundColor: '#C9D1FF',
                    borderRadius: 20,
                    marginLeft: -10,
                  }}>
                  <UserAvatar avatar={guarantors[1].avatar} size={20} />
                </View>
              )}
              <Text style={[a.text_xs, a.font_extrabold]}>
                {`${guarantors.length} `}
                <Trans>Guarantors</Trans>
              </Text>
              {/* <ArrowTriangleBottom
                size="xxs"
                style={{
                  color: t.palette.contrast_975,
                  width: 6,
                  height: 6,
                  marginTop: 2,
                }}
              /> */}
            </Pressable>
          )}
          {/* Tipping */}
          {(!outside || !!totalDonateValue) && !isNative && (
            <Pressable
              accessibilityRole="button"
              testID={`postDonateList-by-${post.uri}`}
              // href={`/bet/${post.author.did}/donate/${new AtUri(post.uri).rkey}`}
              onPress={onPressDonate}
              style={[
                styles.showStakeBtn,
                {backgroundColor: t.palette.green_10},
                {display: 'none'},
              ]}>
              {!outside && totalDonateValue ? (
                <StackAvatar
                  data={
                    donateSummary?.profiles?.map(m => m?.avatar ?? '') ?? []
                  }
                  size={20}
                  emptyIcon={<DonateOnIcon style={[a.ml_xs]} />}
                  bgColor={t.palette.green_10}
                />
              ) : (
                <DonateOnIcon style={{marginLeft: 4, marginRight: 2}} />
              )}
              <Text style={[a.text_xs, a.font_extrabold]}>
                {totalDonateValue > 0
                  ? `${formatShortOnly(totalDonateValue, 2)} Sponsors`
                  : 'Tipping'}
              </Text>
              {/* <ArrowTriangleBottom
                size="xxs"
                style={{
                  color: t.palette.contrast_975,
                  width: 6,
                  height: 6,
                  marginTop: 2,
                }}
              /> */}
            </Pressable>
          )}
        </View>
      )}
    </>
  )
}

export const StackAvatar = ({
  data,
  maxCount = 3,
  emptyIcon,
  size = 24,
  type = 'user',
  bgColor = '#fff',
}: {
  data: string[]
  maxCount?: number
  emptyIcon?: React.ReactNode
  size?: number
  type?: 'user' | 'labeler'
  bgColor?: string
}) => {
  const avatars: string[] = []
  const absoluteWidth = type === 'user' ? size / 2 : (size / 3) * 2
  let width = 15
  data?.forEach(item => {
    if (item && avatars.length < maxCount) {
      avatars.push(item)
      width += absoluteWidth
    }
  })

  return (
    <View
      style={[a.flex_row, a.relative, {width: avatars.length ? width : 20}]}>
      {avatars.length > 0
        ? avatars?.map((item, index: number) => {
            return item ? (
              <View
                style={[
                  styles.stackAvatIcon,
                  {width: size + 4, height: size + 4, backgroundColor: bgColor},
                  index > 0
                    ? {position: 'absolute', left: absoluteWidth * index}
                    : {},
                  type === 'user'
                    ? {borderRadius: (size + 4) / 2}
                    : {borderRadius: 6},
                ]}
                key={index}>
                <Image
                  source={{uri: item ?? ''}}
                  style={[
                    {width: size, height: size},
                    type === 'user'
                      ? {borderRadius: size / 2}
                      : {borderRadius: 6},
                  ]}
                  accessibilityIgnoresInvertColors
                />
              </View>
            ) : null
          })
        : emptyIcon}
    </View>
  )
}

const styles = StyleSheet.create({
  transContent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 10,
    marginBottom: 20,
  },
  transBtnIcon: {
    width: 20,
    height: 20,
    borderRadius: 10,
  },
  showStakeBtn: {
    height: 24,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 3,
    borderRadius: 30,
    paddingRight: 10,
    paddingLeft: 2,
  },
  stackAvatIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    // width: 24,
    // height: 24,
    // borderRadius: 12,
    // marginRight: 10,
  },
})
