import 'lib/sentry' // must be near top
import 'view/icons'
import './vconsole'

import React, {useEffect, useState} from 'react'
import {KeyboardProvider} from 'react-native-keyboard-controller'
import {RootSiblingParent} from 'react-native-root-siblings'
import {SafeAreaProvider} from 'react-native-safe-area-context'
// import {useFonts} from 'expo-font'
import {ModerationDecision} from '@atproto/api'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useIntentHandler} from '#/lib/hooks/useIntentHandler'
import {QueryProvider} from '#/lib/react-query'
import {Provider as StatsigProvider} from '#/lib/statsig/statsig'
import {ThemeProvider} from '#/lib/ThemeContext'
import I18nProvider from '#/locale/i18nProvider'
import {logger} from '#/logger'
import {Provider as A11yProvider} from '#/state/a11y'
import {Provider as MutedThreadsProvider} from '#/state/cache/thread-mutes'
import {Provider as DialogStateProvider} from '#/state/dialogs'
import {emitter, listenSessionDropped} from '#/state/events'
import {Provider as InvitesStateProvider} from '#/state/invites'
import {Provider as LightboxStateProvider} from '#/state/lightbox'
import {MessagesProvider} from '#/state/messages'
import {Provider as ModalStateProvider} from '#/state/modals'
import {init as initPersistedState} from '#/state/persisted'
import {
  Provider as PrefsStateProvider,
  useLanguagePrefsApi,
} from '#/state/preferences'
import {Provider as LabelDefsProvider} from '#/state/preferences/label-defs'
import {Provider as ModerationOptsProvider} from '#/state/preferences/moderation-opts'
import {Provider as UnreadNotifsProvider} from '#/state/queries/notifications/unread'
import {
  Provider as SessionProvider,
  SessionAccount,
  useAgent,
  useSession,
  useSessionApi,
} from '#/state/session'
import {readLastActiveAccount} from '#/state/session/util'
import {Provider as ShellStateProvider} from '#/state/shell'
import {Provider as LoggedOutViewProvider} from '#/state/shell/logged-out'
import {Provider as ProgressGuideProvider} from '#/state/shell/progress-guide'
import {Provider as SelectedFeedProvider} from '#/state/shell/selected-feed'
import {Provider as StarterPackProvider} from '#/state/shell/starter-pack'
import {ActiveVideoProvider} from '#/view/com/util/post-embeds/ActiveVideoContext'
import * as Toast from '#/view/com/util/Toast'
import {ToastContainer} from '#/view/com/util/Toast.web'
import {Shell} from '#/view/shell/index.web'
import {ThemeProvider as Alf} from '#/alf'
import {useColorModeTheme} from '#/alf/util/useColorModeTheme'
import {useStarterPackEntry} from '#/components/hooks/useStarterPackEntry'
import {Provider as PortalProvider} from '#/components/Portal'
import {Provider as TourProvider} from '#/tours'
import {BackgroundNotificationPreferencesProvider} from '../modules/expo-background-notification-handler/src/BackgroundNotificationHandlerProvider'
import {postEvent} from './utils/ShareUtil'
import {useNewPostStore} from './view/com/util/sdlStore/newPostStore'
import {useTgStore} from './view/com/util/sdlStore/TgStore'
import {useTwStore} from './view/com/util/sdlStore/TwStore'

try {
  const webApp = (window as any).Telegram.WebApp
  webApp.expand()
  webApp.disableVerticalSwipes()
  postEvent('iframe_ready', {reload_supported: false})
  logger.info(`Telegram.WebApp ready ${webApp.version}`)
} catch (e) {
  logger.error(`Telegram.WebApp failed`, {message: e})
}

function InnerApp() {
  const [isReady, setIsReady] = React.useState(false)
  const {currentAccount, hasSession} = useSession()
  const {resumeSession} = useSessionApi()
  const theme = useColorModeTheme()
  const {_} = useLingui()
  useIntentHandler()
  const hasCheckedReferrer = useStarterPackEntry()
  const agent = useAgent()
  useAppListeners()

  // init
  useEffect(() => {
    async function onLaunch(account?: SessionAccount) {
      try {
        if (account) {
          await resumeSession(account)
        }
      } catch (e) {
        logger.error(`session: resumeSession failed`, {message: e})
      } finally {
        setIsReady(true)
      }
    }
    const account = readLastActiveAccount()
    onLaunch(account)
  }, [resumeSession])

  useEffect(() => {
    return listenSessionDropped(() => {
      Toast.show(
        _(msg`Sorry! Your session expired. Please log in again.`),
        'info',
      )
    })
  }, [_])

  const {syncRemoteSettings} = useLanguagePrefsApi()

  useEffect(() => {
    const languageSetting = (data: string) => {
      if (!hasSession) return
      agent.com.atproto.server
        .storeAccountSettings({
          settings: JSON.stringify(data),
        })
        .catch(e => console.error(e))
    }
    emitter.on('languagePrefsChanged', languageSetting)
    return () => {
      emitter.off('languagePrefsChanged', languageSetting)
    }
  }, [agent.com.atproto.server, hasSession])

  useEffect(() => {
    if (hasSession) {
      agent.com.atproto.server
        .getAccountSettings()
        .then(res => {
          if (!res.data.settings) return
          const setting = JSON.parse(res.data.settings)
          syncRemoteSettings(setting)
        })
        .catch(e => console.error(e))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSession])

  const {getTelegramInfo} = useTgStore()
  const {getTwitterInfo} = useTwStore()

  useEffect(() => {
    if (currentAccount?.did) {
      getTelegramInfo()
      getTwitterInfo()
    }
  }, [currentAccount, getTelegramInfo, getTwitterInfo])

  // wait for session to resume
  if (!isReady || !hasCheckedReferrer) return null

  return (
    <KeyboardProvider enabled={false}>
      <Alf theme={theme}>
        <ThemeProvider theme={theme}>
          <RootSiblingParent>
            <ActiveVideoProvider>
              <React.Fragment
                // Resets the entire tree below when it changes:
                key={currentAccount?.did}>
                <QueryProvider currentDid={currentAccount?.did}>
                  <StatsigProvider>
                    <MessagesProvider>
                      {/* LabelDefsProvider MUST come before ModerationOptsProvider */}
                      <LabelDefsProvider>
                        <ModerationOptsProvider>
                          <LoggedOutViewProvider>
                            <SelectedFeedProvider>
                              <UnreadNotifsProvider>
                                <BackgroundNotificationPreferencesProvider>
                                  <MutedThreadsProvider>
                                    <SafeAreaProvider>
                                      <TourProvider>
                                        <ProgressGuideProvider>
                                          <Shell />
                                        </ProgressGuideProvider>
                                      </TourProvider>
                                    </SafeAreaProvider>
                                  </MutedThreadsProvider>
                                </BackgroundNotificationPreferencesProvider>
                              </UnreadNotifsProvider>
                            </SelectedFeedProvider>
                          </LoggedOutViewProvider>
                        </ModerationOptsProvider>
                      </LabelDefsProvider>
                    </MessagesProvider>
                  </StatsigProvider>
                </QueryProvider>
              </React.Fragment>
              <ToastContainer />
            </ActiveVideoProvider>
          </RootSiblingParent>
        </ThemeProvider>
      </Alf>
    </KeyboardProvider>
  )
}

function App() {
  const [isReady, setReady] = useState(false)

  React.useEffect(() => {
    initPersistedState().then(() => setReady(true))
  }, [])

  if (!isReady) {
    return null
  }

  /*
   * NOTE: only nothing here can depend on other data or session state, since
   * that is set up in the InnerApp component above.
   */
  return (
    <A11yProvider>
      <SessionProvider>
        <ShellStateProvider>
          <PrefsStateProvider>
            <InvitesStateProvider>
              <ModalStateProvider>
                <DialogStateProvider>
                  <LightboxStateProvider>
                    <I18nProvider>
                      <PortalProvider>
                        <StarterPackProvider>
                          <InnerApp />
                        </StarterPackProvider>
                      </PortalProvider>
                    </I18nProvider>
                  </LightboxStateProvider>
                </DialogStateProvider>
              </ModalStateProvider>
            </InvitesStateProvider>
          </PrefsStateProvider>
        </ShellStateProvider>
      </SessionProvider>
    </A11yProvider>
  )
}

export default App

export const useAppListeners = () => {
  const {currentAccount} = useSession()
  const {updateLocalNewPost, updateLocalPost} = useNewPostStore()

  const agent = useAgent()

  useEffect(() => {
    const addNewPost = async (uri: string) => {
      let retryTime = 0
      async function action() {
        if (retryTime < 3) {
          retryTime++
          try {
            const res = await agent.getPostThread({
              uri: uri!,
              depth: 0,
            })
            if (res.success) {
              const key = `slice-${uri}-${res.data.thread.post.record.createdAt}`
              const tempPost = {
                type: 'slice',
                key: key,
                slice: {
                  _isFeedPostSlice: true,
                  _reactKey: key,
                  items: [
                    {
                      _reactKey: key,
                      uri,
                      post: res.data.thread.post,
                      record: res.data.thread.post.record,
                      isLocal: true,
                      // moderation: new ModerationDecision()
                      moderation: Object.assign(new ModerationDecision(), {
                        did: res.data.thread.post.author.did,
                        isMe: true,
                      }),
                    },
                  ],
                  isIncompleteThread: false,
                  isFallbackMarker: false,
                  feedContext: undefined,
                },
              }
              if (res.data.thread.post.record.visibleCircles[0] === 'Circle') {
                updateLocalNewPost(tempPost, 'circle')
              } else {
                updateLocalNewPost(tempPost, 'public')
              }
            }
          } catch (error) {
            setTimeout(action, 2000)
          }
        }
      }
      setTimeout(action, 2000)
    }
    emitter.on('addNewPost', addNewPost)
    emitter.on('updateLocalPost', updateLocalPost)
    return () => {
      emitter.off('addNewPost', addNewPost)
      emitter.off('updateLocalPost', updateLocalPost)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, agent, updateLocalPost])
}
