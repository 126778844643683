import React from 'react'
import {Text, View} from 'react-native'
import {Image as RNImage} from 'react-native-image-crop-picker'
import {Trans} from '@lingui/macro'

import {isDev} from '#/lib/constants'
import {openPicker} from '#/lib/media/picker'
import {logger} from '#/logger'
import {useModalControls} from '#/state/modals'
import {
  //   useCameraPermission,
  usePhotoLibraryPermission,
} from 'lib/hooks/usePermissions'
import {isMobileWeb, isNative, isWeb} from 'platform/detection'
import {LoggedOutScreenState} from '#/view/com/auth/LoggedOut'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {IconCircle} from '#/components/IconCircle'
import {Picture} from '#/components/icons/Picture'

const return_to = !isDev
  ? 'https%3A%2F%2Fsipz.io%2F'
  : 'https%3A%2F%2Fapp-test.insider.win%2F'
const bot_id = !isDev ? '7463156589' : '7505955242'
const origin = return_to

export const uri = `https://oauth.telegram.org/auth?bot_id=${bot_id}&origin=${origin}&embed=1&request_access=read&return_to=${return_to}`

export function WalletImport({
  onSelectNewWallet,
  // onPressScanner = () => {},
  setScreenState,
}: {
  onSelectNewWallet?: (img: RNImage | null) => void
  onPressScanner?: () => void
  setScreenState: (screen: LoggedOutScreenState) => void
}) {
  const t = useTheme()
  const {openModal} = useModalControls()
  const {requestPhotoAccessIfNeeded} = usePhotoLibraryPermission()

  const onOpenLibrary = React.useCallback(async () => {
    if (!(await requestPhotoAccessIfNeeded())) {
      return
    }
    const items = await openPicker()
    if (!items[0]) {
      return
    }

    let [item] = items

    try {
      onSelectNewWallet?.(item)
    } catch (e: any) {
      if (!String(e).includes('Canceled')) {
        logger.error('Failed to crop banner', {error: e})
      }
    }
  }, [onSelectNewWallet, requestPhotoAccessIfNeeded])

  // const onRemoveBanner = React.useCallback(() => {
  //   onSelectNewWallet?.(null)
  // }, [onSelectNewWallet])

  // setUserBanner is only passed as prop on the EditProfile component
  return (
    <View style={{flex: 1}}>
      <Text
        style={[
          {color: t.palette.gray_10, fontWeight: 500},
          a.mb_md,
          a.text_md,
        ]}>
        <Trans>Login via your saved QR code or telegram</Trans>
      </Text>
      <View>
        <Button
          style={[
            a.flex_1,
            a.border,
            a.rounded_full,
            a.overflow_hidden,
            a.mb_lg,
            t.atoms.border_contrast_medium,
          ]}
          label="Login with qrcode image"
          onPress={onOpenLibrary}>
          <View
            style={[
              a.flex_1,
              a.flex_row,
              a.rounded_md,
              a.align_center,
              {height: 56},
              // (hovered || pressed || isPendingAccount) && t.atoms.bg_contrast_25,
            ]}>
            <View style={[a.p_sm]}>
              <IconCircle
                size="md"
                style={[
                  t.atoms.bg_primary,
                  {width: 44, height: 44, backgroundColor: '#B4B3D3'},
                ]}
                iconStyle={[t.atoms.text_inverted]}
                icon={Picture}
              />
            </View>
            <View style={[a.flex_1, a.py_sm]}>
              <Text style={[a.font_bold, t.atoms.text]}>
                <Trans>Open saved QR from Gallery</Trans>
              </Text>
            </View>
          </View>
        </Button>
        {
          <Button
            style={[
              a.flex_1,
              a.border,
              a.rounded_full,
              a.overflow_hidden,
              a.mb_lg,
              t.atoms.border_contrast_medium,
            ]}
            label="Login with telegram"
            onPress={() => {
              if (isMobileWeb || isWeb) {
                location.href = uri
              } else if (isNative) {
                openModal({
                  name: 'telegram-bot-auth',
                  url: uri,
                  onSuccess: () => {
                    setScreenState(LoggedOutScreenState.S_CreateAccount)
                  },
                })
              }
            }}>
            <View
              style={[
                a.flex_1,
                a.flex_row,
                a.rounded_md,
                a.align_center,
                {height: 56},
                // (hovered || pressed || isPendingAccount) && t.atoms.bg_contrast_25,
              ]}>
              <View style={[a.p_sm]}>
                <IconCircle
                  size="lg"
                  style={[
                    t.atoms.bg_primary,
                    {width: 44, height: 44, backgroundColor: '#1DB7E8'},
                  ]}
                  iconStyle={[t.atoms.text, {left: -2, top: 1}]}
                  icon={Telegram}
                />
              </View>
              <View style={[a.flex_1, a.py_sm]}>
                <Text style={[a.font_bold, t.atoms.text]}>
                  <Trans>Telegram</Trans>
                </Text>
              </View>
            </View>
          </Button>
        }
      </View>
    </View>
  )
}
