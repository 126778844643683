import React, {useCallback, useMemo} from 'react'
import {
  Pressable,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import {Image} from 'expo-image'

import {useModalControls} from '#/state/modals'
import {useCheckSyncTelegramContacts} from '#/state/queries/profile'
import {useTheme} from '#/alf'
import {TelegramBindStep} from '../modals/TelegramModal/config'
import {useTgStore} from '../util/sdlStore/TgStore'
import {Text} from '../util/text/Text'

export interface TabBarProps {
  testID?: string
  selectedPage: number
  items: string[]
  indicatorColor?: string
  textStyle?: StyleProp<TextStyle>
  selectedTextStyle?: StyleProp<TextStyle>
  style?: StyleProp<ViewStyle>
  bgColor?: string
  hideBorder?: boolean
  onSelect?: (index: number) => void
  onPressSelected?: (index: number) => void
  hasMenu?: boolean
  isCentered?: boolean
  isBold?: boolean
  rightView?: JSX.Element
  isHoverStyle?: boolean
}

export function HomeTabBar({
  testID,
  selectedPage,
  items,
  onSelect,
  onPressSelected,
}: TabBarProps) {
  const {data: synced, isFetched: checked} = useCheckSyncTelegramContacts(true)
  const {bind: hasBindTelegram} = useTgStore(),
    {openModal} = useModalControls()

  const onPressItem = useCallback(
    (index: number) => {
      onSelect?.(index)
      if (index === selectedPage) {
        onPressSelected?.(index)
      }
    },
    [onSelect, selectedPage, onPressSelected],
  )

  const t = useTheme()

  const isDark = useMemo(() => {
    return t.name === 'dark'
  }, [t.name])

  return (
    <View testID={testID} style={[styles.page]}>
      <View style={{height: 3, width: 50}} />
      <View
        style={[
          styles.selectSpace,
          isDark
            ? {backgroundColor: '#0C0B0A', borderColor: '#2A261C'}
            : {backgroundColor: '#F4F4F4', borderColor: 'rgba(0,0,0,0.1)'},
        ]}>
        {items.map((item, i) => {
          const selected = i === selectedPage
          return (
            <Pressable
              accessibilityRole="button"
              onPress={() => onPressItem(i)}
              key={i}
              style={[styles.selectBt, selected && t.atoms.upperBg]}>
              <Text
                style={[
                  t.atoms.grayText,
                  selected && {...t.atoms.text, fontWeight: 'bold'},
                ]}>
                {item}
              </Text>
            </Pressable>
          )
        })}
      </View>
      {checked && synced ? (
        <TouchableOpacity
          accessibilityRole="button"
          onPress={() => {
            if (hasBindTelegram) {
              openModal({
                name: 'telegram',
                currentStep: TelegramBindStep.SelectContact,
              })
            } else {
              openModal({name: 'telegram'})
            }
          }}
          style={[
            styles.menuBt,
            {backgroundColor: '#FCD010', borderColor: 'transparent'},
          ]}>
          <Image
            style={{height: '100%', width: '100%'}}
            source={require('../../../../assets/imgs/invite_image.png')}
            accessibilityIgnoresInvertColors={true}
          />
        </TouchableOpacity>
      ) : (
        <View style={{height: 3, width: 50}} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 45,
    paddingHorizontal: 10,
    paddingRight: 30,
  },
  selectSpace: {
    height: 45,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    borderWidth: 1,
    borderColor: '#242424',
    padding: 6,
    gap: 6,
  },
  selectBt: {
    borderRadius: 8,
    height: '100%',
    width: 92,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuBt: {
    width: 50,
    height: 45,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    borderWidth: 1,
    borderColor: '#242424',
  },
})
