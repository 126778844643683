import React, {useCallback} from 'react'
import {Image, TouchableOpacity, View} from 'react-native'
import Animated from 'react-native-reanimated'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigationState} from '@react-navigation/native'

import {useMinimalShellFooterTransform} from '#/lib/hooks/useMinimalShellTransform'
import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {getCurrentRoute, isTab} from '#/lib/routes/helpers'
import {makeProfileLink} from '#/lib/routes/links'
import {CommonNavigatorParams} from '#/lib/routes/types'
import {s} from '#/lib/styles'
import {useModalControls} from '#/state/modals'
import {useSession} from '#/state/session'
import {useComposerControls} from '#/state/shell'
import {useLoggedOutViewControls} from '#/state/shell/logged-out'
import {useSelectedFeed} from '#/state/shell/selected-feed'
import {useCloseAllActiveElements} from '#/state/util'
import {Button} from '#/view/com/util/forms/Button'
import {useTwStore} from '#/view/com/util/sdlStore/TwStore'
import {Text} from '#/view/com/util/text/Text'
import {
  BottomBarBG,
  CircleIcon,
  HomeIcon,
  ProfileIcon,
  TaskIcon,
  // SearchIcon,
} from '#/view/icons/BottomIcons'
import {NavLogo} from '#/view/icons/NavLogo'
import {Link} from 'view/com/util/Link'
import {atoms as a, useTheme} from '#/alf'
import {styles} from './BottomBarStyles'

export function BottomBarWeb() {
  const {_} = useLingui()
  const {hasSession, currentAccount} = useSession()
  const pal = usePalette('default')
  const footerMinimalShellTransform = useMinimalShellFooterTransform()
  const {
    requestSwitchToAccount,
    // setShowLoggedOut
  } = useLoggedOutViewControls()
  const closeAllActiveElements = useCloseAllActiveElements()
  const t = useTheme()
  const {isMobile} = useWebMediaQueries()

  const {openComposer} = useComposerControls()

  const {openModal} = useModalControls()

  const selectedFeed = useSelectedFeed()

  const {isBoundTw} = useTwStore()

  const hasBindTelegram = true
  // {bind: hasBindTelegram} = useTgStore()

  const validatePublish = useCallback(async () => {
    if (selectedFeed?.includes('circle')) {
      if (!hasBindTelegram) {
        openModal({name: 'telegram'})
        return
      }
    } else {
      if (!hasBindTelegram && !isBoundTw) {
        openModal({name: 'link-social'})
        return
      }
    }
    openComposer({})
  }, [selectedFeed, openComposer, hasBindTelegram, openModal, isBoundTw])

  const showSignIn = React.useCallback(() => {
    closeAllActiveElements()
    requestSwitchToAccount({requestedAccount: 'none'})
  }, [requestSwitchToAccount, closeAllActiveElements])

  const showCreateAccount = React.useCallback(() => {
    closeAllActiveElements()
    requestSwitchToAccount({requestedAccount: 'new'})
    // setShowLoggedOut(true)
  }, [requestSwitchToAccount, closeAllActiveElements])

  return !hasSession && isMobile ? (
    <Animated.View
      style={[
        a.px_2xl,
        a.align_center,
        styles.bottomBar,
        styles.bottomBarWeb,
        {height: 100},
        footerMinimalShellTransform,
      ]}>
      <View
        style={[
          a.w_full,
          {borderRadius: 100, backgroundColor: t.palette.white},
        ]}>
        <Button
          style={[
            a.w_full,
            t.atoms.bg_primary,
            a.align_center,
            {height: 50, borderRadius: 100},
            styles.webBoxShadow,
          ]}
          onPress={() => setShowLoggedOut(true)}
          accessibilityHint={_(msg`Login to Sip`)}
          accessibilityLabel={_(msg`Login to Sip`)}>
          <Text
            style={[
              a.h_full,
              a.flex,
              a.justify_center,
              a.align_center,
              a.text_md,
              a.font_bold,
            ]}>
            <Trans>Login to Sip</Trans>
          </Text>
        </Button>
      </View>
    </Animated.View>
  ) : (
    <Animated.View
      style={[
        styles.bottomBar,
        styles.bottomBarWeb,
        pal.view,
        {height: 100},
        footerMinimalShellTransform,
        {backgroundColor: 'transparent'},
      ]}>
      <View
        style={{
          flex: 1,
          height: 90,
          width: '100%',
          transform: [{translateY: 0}],
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          position: 'relative',
        }}>
        <BottomBarBG />
        {hasSession ? (
          <>
            <NavItem routeName="Home" href="/">
              {({isActive}) => {
                return (
                  <View style={styles.navItemBox}>
                    <HomeIcon
                      size={25}
                      style={styles.ctrlIcon}
                      active={isActive}
                    />
                    <Text
                      style={[
                        styles.navText,
                        t.atoms.text,
                        isActive && {fontWeight: 'bold'},
                      ]}>
                      <Trans>Home</Trans>
                    </Text>
                  </View>
                )
              }}
            </NavItem>

            {hasBindTelegram ? (
              <NavItem routeName="Connections" href="/Connections">
                {({isActive}) => {
                  return (
                    <View style={styles.navItemBox}>
                      <CircleIcon
                        size={25}
                        style={styles.ctrlIcon}
                        active={isActive}
                      />
                      <Text
                        style={[
                          styles.navText,
                          t.atoms.text,
                          isActive && {fontWeight: 'bold'},
                        ]}>
                        <Trans>Connections</Trans>
                      </Text>
                    </View>
                  )
                }}
              </NavItem>
            ) : (
              <NavItem routeName="Connections" href="#">
                {({isActive}) => {
                  return (
                    <TouchableOpacity
                      accessibilityRole="button"
                      onPress={() => openModal({name: 'telegram'})}>
                      <View style={styles.navItemBox}>
                        <CircleIcon
                          size={25}
                          style={styles.ctrlIcon}
                          active={isActive}
                        />
                        <Text
                          style={[
                            styles.navText,
                            t.atoms.text,
                            isActive && {fontWeight: 'bold'},
                          ]}>
                          <Trans>Connections</Trans>
                        </Text>
                      </View>
                    </TouchableOpacity>
                  )
                }}
              </NavItem>
            )}

            {/* <NavItem routeName="Messages" href="/messages">
                {({isActive}) => {
                  return  <PostIcon size= {25} style={styles.ctrlIcon} active ={isActive}/>
                }}
              </NavItem> */}
            <View style={styles.ctrl}>
              <TouchableOpacity
                accessibilityRole="button"
                style={{height: 24, position: 'relative'}}
                onPress={validatePublish}>
                <View style={[styles.postBox, {top: -32}]}>
                  <Image
                    style={{height: 50, width: 50, borderRadius: 100}}
                    source={{uri: '/spill.gif'}}
                    accessibilityIgnoresInvertColors={true}
                  />
                </View>
              </TouchableOpacity>
            </View>

            <NavItem routeName="Task" href="/task">
              {({isActive}) => {
                return (
                  <View style={styles.navItemBox}>
                    <TaskIcon
                      size={25}
                      style={styles.ctrlIcon}
                      active={isActive}
                    />
                    <Text
                      style={[
                        styles.navText,
                        t.atoms.text,
                        isActive && {fontWeight: 'bold'},
                      ]}>
                      <Trans>Task</Trans>
                    </Text>
                  </View>
                )
              }}
            </NavItem>

            <NavItem
              routeName="Profile"
              href={
                currentAccount
                  ? makeProfileLink({
                      did: currentAccount.did,
                      handle: currentAccount.handle,
                    })
                  : '/'
              }>
              {({isActive}) => (
                <View style={styles.navItemBox}>
                  <ProfileIcon
                    size={25}
                    style={styles.ctrlIcon}
                    active={isActive}
                  />
                  <Text
                    style={[
                      styles.navText,
                      t.atoms.text,
                      isActive && {fontWeight: 'bold'},
                      {marginTop: 7},
                    ]}>
                    <Trans>Profile</Trans>
                  </Text>
                </View>
              )}
            </NavItem>
          </>
        ) : (
          <>
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingTop: 14,
                paddingBottom: 2,
                paddingLeft: 14,
                paddingRight: 6,
                gap: 8,
              }}>
              <View
                style={{flexDirection: 'row', alignItems: 'center', gap: 12}}>
                <NavLogo width={50} height={50} />
              </View>

              <View
                style={{flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <Button
                  style={[t.atoms.bg_primary, {width: 90}]}
                  onPress={showCreateAccount}
                  accessibilityHint={_(msg`Sign up`)}
                  accessibilityLabel={_(msg`Sign up`)}>
                  <Text type="md" style={[s.bold, s.textCenter]}>
                    <Trans>Sign up</Trans>
                  </Text>
                </Button>

                <Button
                  style={[{width: 90}]}
                  type="default"
                  onPress={showSignIn}
                  accessibilityHint={_(msg`Login`)}
                  accessibilityLabel={_(msg`Login`)}>
                  <Text type="md" style={[pal.text, s.bold, s.textCenter]}>
                    <Trans>Login</Trans>
                  </Text>
                </Button>
              </View>
            </View>
          </>
        )}
      </View>
    </Animated.View>
  )
}

const NavItem: React.FC<{
  children: (props: {isActive: boolean}) => React.ReactChild
  href: string
  routeName: string
}> = ({children, href, routeName}) => {
  const {currentAccount} = useSession()
  const currentRoute = useNavigationState(state => {
    if (!state) {
      return {name: 'Home'}
    }
    return getCurrentRoute(state)
  })
  const isActive =
    currentRoute.name === 'Profile'
      ? isTab(currentRoute.name, routeName) &&
        (currentRoute.params as CommonNavigatorParams['Profile']).name ===
          currentAccount?.handle
      : isTab(currentRoute.name, routeName)

  return (
    <Link href={href} style={styles.ctrl} navigationAction="navigate">
      {children({isActive})}
    </Link>
  )
}
