import React, {useCallback, useEffect, useState} from 'react'
import {Image, ImageBackground, TouchableOpacity, View} from 'react-native'
import Animated from 'react-native-reanimated'
import {BottomTabBarProps} from '@react-navigation/bottom-tabs'
import {StackActions, useFocusEffect} from '@react-navigation/native'

import {useAnalytics} from '#/lib/analytics/analytics'
import {useDedupe} from '#/lib/hooks/useDedupe'
import {useMinimalShellFooterTransform} from '#/lib/hooks/useMinimalShellTransform'
import {useNavigationTabState} from '#/lib/hooks/useNavigationTabState'
import {usePalette} from '#/lib/hooks/usePalette'
import {getTabState, TabState} from '#/lib/routes/helpers'
import {emitSoftReset, emitter} from '#/state/events'
import {useModalControls} from '#/state/modals'
import {useComposerControls} from '#/state/shell'
import {ComposerOpts} from '#/state/shell/composer'
import {useShellLayout} from '#/state/shell/shell-layout'
import {
  BottomBarBG,
  CircleIcon,
  HomeIcon,
  NotificationsIcon,
  ProfileIcon,
} from '#/view/icons/BottomIcons'
import {styles} from './BottomBarStyles'

type TabOptions =
  | 'Home'
  | 'Search'
  | 'Notifications'
  | 'MyProfile'
  | 'Feeds'
  | 'Messages'
  | 'Connections'

export function BottomBar({navigation}: BottomTabBarProps) {
  const pal = usePalette('default')
  const {track} = useAnalytics()
  const {footerHeight} = useShellLayout()
  const {isAtHome, isAtConnections, isAtNotifications, isAtMyProfile} =
    useNavigationTabState()
  const footerMinimalShellTransform = useMinimalShellFooterTransform()
  const dedupe = useDedupe()
  const [bottomBarVisible, setBottomBarVisible] = useState<boolean>(false)
  const {bind: hasBindTelegram} = useTgStore()
  const {openModal} = useModalControls()

  const onPressTab = React.useCallback(
    (tab: TabOptions) => {
      track(`MobileShell:${tab}ButtonPressed`)
      const state = navigation.getState()
      const tabState = getTabState(state, tab)
      if (tabState === TabState.InsideAtRoot) {
        emitSoftReset()
      } else if (tabState === TabState.Inside) {
        dedupe(() => navigation.dispatch(StackActions.popToTop()))
      } else {
        dedupe(() => navigation.navigate(`${tab}Tab`))
      }
    },
    [track, navigation, dedupe],
  )
  const onPressHome = React.useCallback(() => onPressTab('Home'), [onPressTab])
  const onPressConnections = React.useCallback(() => {
    if (hasBindTelegram) {
      onPressTab('Connections')
    } else {
      openModal({name: 'telegram'})
    }
  }, [hasBindTelegram, onPressTab, openModal])
  const onPressNotifications = React.useCallback(
    () => onPressTab('Notifications'),
    [onPressTab],
  )
  const onPressProfile = React.useCallback(() => {
    onPressTab('MyProfile')
  }, [onPressTab])

  const {openComposer} = useComposerControls()
  // {bind: hasBindTelegram} = useTgStore()

  const validatePublish = useCallback(
    async (opts: ComposerOpts) => {
      if (!hasBindTelegram) {
        openModal({name: 'telegram'})
        return
      }
      openComposer(opts)
    },
    [openComposer, hasBindTelegram, openModal],
  )

  useEffect(() => {
    const toggleBottomNav = (flag: boolean) => {
      setBottomBarVisible(flag)
    }
    emitter.on('toggleBottomNav', toggleBottomNav)
    return () => {
      emitter.off('toggleBottomNav', toggleBottomNav)
    }
  }, [])

  return (
    <Animated.View
      style={[
        styles.bottomBar,
        pal.view,
        bottomBarVisible
          ? footerMinimalShellTransform
          : {transform: [{translateY: 110}]},
      ]}
      onLayout={e => {
        footerHeight.value = e.nativeEvent.layout.height
      }}>
      <ImageBackground
        resizeMode="stretch"
        style={{
          height: 80,
          width: '100%',
          transform: [{translateY: -30}],
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          // paddingTop: 20,
        }}>
        <BottomBarBG />

        <TouchableOpacity
          accessibilityRole="button"
          style={styles.tabItem}
          onPress={onPressHome}>
          <HomeIcon active={isAtHome} />
        </TouchableOpacity>

        <TouchableOpacity
          accessibilityRole="button"
          style={styles.tabItem}
          onPress={onPressConnections}>
          <CircleIcon active={isAtConnections} />
        </TouchableOpacity>

        <TouchableOpacity
          accessibilityRole="button"
          style={styles.tabItem}
          onPress={() => validatePublish({})}>
          <View style={[styles.postBox, {top: -32}]}>
            <Image
              style={{height: 50, width: 50}}
              source={require('../../../../assets/imgs/post_icon.png')}
              accessibilityIgnoresInvertColors={true}
            />
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          accessibilityRole="button"
          style={styles.tabItem}
          onPress={onPressNotifications}>
          <NotificationsIcon active={isAtNotifications} />
        </TouchableOpacity>

        <TouchableOpacity
          accessibilityRole="button"
          style={styles.tabItem}
          onPress={onPressProfile}>
          <ProfileIcon active={isAtMyProfile} />
        </TouchableOpacity>
      </ImageBackground>
    </Animated.View>
  )
}

export const useShowBottomBar = () => {
  useFocusEffect(
    React.useCallback(() => {
      emitter.emit('toggleBottomNav', true)
    }, []),
  )
}

export const useHideBottomBar = () => {
  useFocusEffect(
    React.useCallback(() => {
      emitter.emit('toggleBottomNav', false)
    }, []),
  )
}
