import React from 'react'
import Svg, {
  ClipPath,
  Defs,
  G,
  LinearGradient,
  Path,
  Rect,
  Stop,
} from 'react-native-svg'

export const Telegram = () => {
  return (
    <Svg width="48" height="48" viewBox="0 0 48 48" fill="none">
      <G clipPath="url(#clip0_1225_14265)">
        <Path
          d="M24 0C17.6362 0 11.5275 2.53013 7.03125 7.02938C2.5304 11.5304 0.00128793 17.6347 0 24C0 30.3626 2.53125 36.4714 7.03125 40.9706C11.5275 45.4699 17.6362 48 24 48C30.3638 48 36.4725 45.4699 40.9688 40.9706C45.4688 36.4714 48 30.3626 48 24C48 17.6374 45.4688 11.5286 40.9688 7.02938C36.4725 2.53013 30.3638 0 24 0Z"
          fill="url(#paint0_linear_1225_14265)"
        />
        <Path
          d="M10.8637 23.7466C17.8612 20.6986 22.5262 18.6891 24.8587 17.7181C31.5262 14.9457 32.9099 14.4642 33.8137 14.4479C34.0124 14.4447 34.4549 14.4938 34.7437 14.7273C34.9837 14.9241 35.0512 15.1904 35.0849 15.3773C35.1149 15.5641 35.1562 15.9897 35.1224 16.322C34.7624 20.117 33.1987 29.3262 32.4037 33.5768C32.0699 35.3753 31.4062 35.9783 30.7649 36.0372C29.3699 36.1655 28.3124 35.1162 26.9624 34.2316C24.8512 32.8467 23.6587 31.985 21.6074 30.6338C19.2374 29.0723 20.7749 28.214 22.1249 26.8115C22.4774 26.4443 28.6199 20.8587 28.7362 20.3521C28.7512 20.2887 28.7662 20.0525 28.6237 19.928C28.4849 19.8031 28.2787 19.8458 28.1287 19.8796C27.9149 19.9276 24.5437 22.1581 18.0037 26.5707C17.0474 27.2285 16.1812 27.5491 15.4012 27.5322C14.5462 27.5138 12.8962 27.0477 11.6699 26.6495C10.1699 26.1608 8.97369 25.9025 9.07869 25.0726C9.13119 24.6406 9.72744 24.1985 10.8637 23.7466Z"
          fill="white"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_1225_14265"
          x1="2400"
          y1="0"
          x2="2400"
          y2="4800"
          gradientUnits="userSpaceOnUse">
          <Stop stop-color="#2AABEE" />
          <Stop offset="1" stop-color="#229ED9" />
        </LinearGradient>
        <ClipPath id="clip0_1225_14265">
          <Rect width="48" height="48" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
